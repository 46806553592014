import { defineComponent } from '@vue/composition-api';
import { ref } from 'vue';
import { CHANNEL_STATE, SETTLE_LIST } from '../../http/api';
import { Toast } from 'vant';
export default defineComponent({
  setup() {
    const payingNum = ref(0);
    const payingFee = ref(0);
    const paySucNum = ref(0);
    const income = ref(0);
    const list = ref(null);
    CHANNEL_STATE({}).then(res => {
      Toast.clear();
      payingNum.value = res.data.data.paying_num;
      payingFee.value = res.data.data.paying_fee / 100;
      paySucNum.value = res.data.data.pay_success_num;
      income.value = res.data.data.income / 100;
    });
    SETTLE_LIST({}).then(res => {
      Toast.clear();
      list.value = res.data.data;
      console.log(res.data.data);
    });
    return {
      payingNum,
      payingFee,
      paySucNum,
      income,
      list
    };
  }

});